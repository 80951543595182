import React from 'react';
import Logo from '../logo.svg';
import '../styles/Nav.css';

function Nav() {
  return (
    <div className="navContainer">
      <img src={ Logo } alt="Liselan official logo" className='navLogo' />
      <div className='navLinks'>
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
      </div>
    </div>
  );
}

export default Nav;