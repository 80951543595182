// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCvS_ehL97pVvzpIouuICznd7p_1LpJcV0",
  authDomain: "grupo-contreras-y-garcia.firebaseapp.com",
  projectId: "grupo-contreras-y-garcia",
  storageBucket: "grupo-contreras-y-garcia.appspot.com",
  messagingSenderId: "969070450357",
  appId: "1:969070450357:web:dc077508e313ce89b250fc",
  measurementId: "G-04S45C2C53"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);