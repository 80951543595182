import React from 'react';
import Nav from '../components/Nav';
import '../styles/About.css';

function About() {
    return(
        <body>
            <Nav></Nav>
            <section className='introduction'>
                <h1 className='mainTitle'>This page is currently under construction.</h1>
            </section>
        </body>
    )
}

export default About;