import React from 'react';
import Nav from '../components/Nav';
import Share from '../assets/share.webp';
import '../styles/Home.css';

function Home() {
    const handleMailButtonClick = () => {
        window.location.href = 'mailto:hello@liselan.com';
    }
    return(
        <body>
            <Nav></Nav>
            <section className='welcome'>
                <h1 className='title'>Creating software for humans, by humans.</h1>
                <button className='actionButton' onClick={handleMailButtonClick}>Contact us</button>
            </section>
        </body>
    )
}

export default Home;