import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Services from '../pages/Services';


interface PrivateRouteProps {
  element: React.ReactNode;
}

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        {/*<Route path="/hub" element={<PrivateRoute element={<Hub />} />} />*/}
      </Routes>
    </Router>
  );
};

export default AppRouter;